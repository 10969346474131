//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from 'vue'
import { ModalPlugin } from 'bootstrap-vue'
import { Slider } from 'element-ui'

Vue.use(ModalPlugin)
Vue.use(Slider)

export default {
  name: 'Simulateur',
  data() {
    return {
      taux: null,
      montant: null,
      duree: null,
      tauxMarks: {
        0: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '0%')
        },
        5: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '5%')
        }
      },
      montantMarks: {
        0: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '0 €')
        },
        2000000: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '2 000 000 €')
        }
      },
      dureeMarks: {
        0: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '0 mois')
        },
        360: {
          style: {
            color: '#49507B'
          },
          label: this.$createElement('small', '360 mois')
        }
      }
    }
  },
  computed: {
    mensualite: function () {
      const mensualite = Math.round(((this.montant * (this.taux / 100)) / 12) / (1 - Math.pow(1 + ( (this.taux / 100) / 12),  -this.duree)))
      return mensualite
    }
  },
  methods: {
    formatTooltipTaux(value) {
      return value + '%'
    },
    formatTooltipMontant(value) {
      value = parseInt(value)
      if (value < 999) {
        return value + ' €'
      }
      return this.$options.filters.formatMoney(value) + ' €'
    },
    formatTooltipDuree(value) {
      value = parseInt(value)
      return value + ' mois'
    },
    showModal() {
      this.$bvModal.show('simulateur')
    }
  }
}
